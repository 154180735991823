import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const vDate = new Date();

    const dateExpressions = {
        "Now()": "LocalDateTimeNow()",
        "Yesterday()": "LocalDateTimeYesterday()",
        "DayAfterTomorow()": "LocalDateTimeDayAfterTomorrow()",
        "LastWeek()": "LocalDateTimeLastWeek()",
        "NextMonth()": "LocalDateTimeNextMonth()",
        "NextWeek()": "LocalDateTimeNextWeek()",
        "NextYear()": "LocalDateTimeNextYear()",
        "ThisMonth()": "LocalDateTimeThisMonth()",
        "ThisWeek()": "LocalDateTimeThisWeek()",
        "ThisYear()": "LocalDateTimeThisYear()",
        "Tomorrow()": "LocalDateTimeTomorrow()",
        "TwoWeeksAway()": "LocalDateTimeTwoWeeksAway()",

        "AddMonths(LocalDateTimeThisMonth(), 2)": "AddMonths(LocalDateTimeThisMonth(), 2)",
        "AddYears(LocalDateTimeThisYear(), 2)": "AddYears(LocalDateTimeThisYear(), 2)",

        "LastYear()": "AddYears(LocalDateTimeThisYear(), -1)",
        "LastMonth()": "AddMonths(LocalDateTimeThisMonth(), -1)",

        "AddDays(AddMonths(LocalDateTimeNextMonth(), 1), -1)": "AddDays(AddMonths(LocalDateTimeNextMonth(), 1), -1)",
        "AddSeconds(AddMonths(LocalDateTimeNextMonth(), 1), -1)": "AddSeconds(AddMonths(LocalDateTimeNextMonth(),1), -1)",

        "AddDays(LocalDateTimeLastWeek(), 6)": "AddDays(LocalDateTimeLastWeek(), 6)",
        "AddDays(LocalDateTimeLastWeek(), 7)": "AddDays(LocalDateTimeLastWeek(),7)",

        "AddDays(LocalDateTimeThisWeek(), 6)": "AddDays(LocalDateTimeThisWeek(), 6)",
        "AddSeconds(AddDays(LocalDateTimeThisWeek(), 7),-1)": "AddSeconds(AddDays(LocalDateTimeThisWeek(),7),-1)",

        "AddDays(LocalDateTimeNextWeek(), 6)": "AddDays(LocalDateTimeNextWeek(), 6)",
        "AddSeconds(AddDays(LocalDateTimeNextWeek(), 7),-1)": "AddSeconds(AddDays(LocalDateTimeNextWeek(),7),-1)",

        "AddDays(LocalDateTimeNextYear(), -1)": "AddDays(LocalDateTimeNextYear(), -1)",
        "AddSeconds(LocalDateTimeNextYear(), -1)": "AddSeconds(LocalDateTimeNextYear(),-1)",

        "AddDays(LocalDateTimeThisYear(), -1)": "AddDays(LocalDateTimeThisYear(), -1)",
        "AddSeconds(LocalDateTimeThisYear(), -1)": "AddSeconds(LocalDateTimeThisYear(),-1)",

        "AddDays(AddYears(LocalDateTimeThisYear(), 2), -1)": "AddDays(AddYears(LocalDateTimeThisYear(), 2), -1)",
        "AddSeconds(AddYears(LocalDateTimeThisYear(), 2),-1)": "AddSeconds(AddYears(LocalDateTimeThisYear(), 2),-1)",

        "AddDays(LocalDateTimeThisMonth(), -1)": "AddDays(LocalDateTimeThisMonth(), -1)",
        "AddSeconds(LocalDateTimeThisMonth(),-1)": "AddSeconds(LocalDateTimeThisMonth(),-1)",

        "AddDays(LocalDateTimeNextMonth(), -1)": "AddDays(LocalDateTimeNextMonth(), -1)",
        "AddSeconds(LocalDateTimeNextMonth(), -1)": "AddSeconds(LocalDateTimeNextMonth(),-1)",

        "AddDays(Today(), -6)": "AddDays(Today(), -6)",
        "AddSeconds(AddDays(Today(), -7), -1)": "AddSeconds(AddDays(Today(), -7), -1)",

        "AddDays(Today(), -13)": "AddDays(Today(), -13)",
        "AddSeconds(AddDays(Today(), -14, -1)": "AddSeconds(AddDays(Today(), -14), -1)",

        "AddDays(Today(), -29)": "AddDays(Today(), -29)",
        "AddSeconds(AddDays(Today(), -30, -1)": "AddSeconds(AddDays(Today(), -30), -1)",
    };

    function tryGeCombinedtDateExpression(pExpression: any) {
        if (!pExpression) return null;

        let vFound = null
        Object.keys(combinedExpressions).forEach(key => {
            let vItem = combinedExpressions[key];
            if (vItem.value.toString() === pExpression.toString()) {

                vFound = Object.assign(vItem, { id: key });
                return;

            }
        })
        return vFound;
    }

    function getCombinedExpressionIndex(pId: string) {
        if (!combinedExpressions.hasOwnProperty(pId)) return null;
        let vIndex = 0;
        Object.keys(combinedExpressions).forEach((key, index) => {
            if (key == pId) {
                vIndex = index;
                return;
            }
        });

        return vIndex;
    }

    function getCombinedExpressionIdByIndex(pIndex: number) {
        let vId: string | null = null;

        Object.keys(combinedExpressions).forEach((key, index) => {
            if (index == pIndex) {
                vId = key;

                return;
            }
        });

        return vId;
    }

    function getCombinedExpressionByID(pId: string) {
        if (!pId) return null;
        return combinedExpressions.hasOwnProperty(pId) ? combinedExpressions[pId] : null;
    }

    const combinedExpressions = {
        yesterday: {
            title: "Yesterday",
            operator: "dateequals",
            value: getDateExpression('LocalDateTimeYesterday()')
        },
        lastMonth: {
            title: "Last month",
            operator: "datebetween",

            value: [
                getDateExpression('AddMonths(LocalDateTimeThisMonth(), -1)'),
                getDateExpression('AddSeconds(LocalDateTimeThisMonth(), -1)')
            ]
        },
        today: {
            title: "Today",
            operator: "dateequals",

            value: getDateExpression('LocalDateTimeNow()')
        },
        thisMonth: {
            title: "This month",

            operator: "datebetween",
            value: [
                getDateExpression('LocalDateTimeThisMonth()'),
                getDateExpression('AddSeconds(LocalDateTimeNextMonth(), -1)')
            ]
        },
        tomorrow: {
            title: "Tomorrow",
            operator: "dateequals",

            value: getDateExpression('LocalDateTimeTomorrow()')

        },
        nextMonth: {
            title: "Next month",
            operator: "datebetween",
            value: [
                getDateExpression('LocalDateTimeNextMonth()'),
                getDateExpression('AddSeconds(AddMonths(LocalDateTimeNextMonth(), 1), -1)')
            ]
        },
        lastWeek: {
            title: "Last week",
            operator: "datebetween",
            value: [
                getDateExpression('LocalDateTimeLastWeek()'),
                getDateExpression('AddDays(LocalDateTimeLastWeek(), 7)')
            ]
        },
        lastYear: {
            title: "Last year",
            operator: "datebetween",
            value: [
                getDateExpression('AddYears(LocalDateTimeThisYear(), -1)'),
                getDateExpression('AddSeconds(LocalDateTimeThisYear(), -1)')
            ]
        },
        thisWeek: {
            title: "This week",
            operator: "datebetween",
            value: [
                getDateExpression('LocalDateTimeThisWeek()'),
                getDateExpression('AddSeconds(AddDays(LocalDateTimeThisWeek(), 7), -1)')
            ]
        },
        thisYear: {
            title: "This year",
            operator: "datebetween",
            value: [
                getDateExpression('LocalDateTimeThisYear()'),
                getDateExpression('AddSeconds(LocalDateTimeNextYear(), -1)')
            ]
        },
        nextWeek: {
            title: "Next week",
            operator: "datebetween",
            value: [
                getDateExpression('LocalDateTimeNextWeek()'),
                getDateExpression('AddSeconds(AddDays(LocalDateTimeNextWeek(), 7), -1)')
            ]
        },
        nextYear: {
            title: "Next year",
            operator: "datebetween",
            value: [
                getDateExpression('LocalDateTimeNextYear()'),
                getDateExpression('AddSeconds(AddYears(LocalDateTimeThisYear(), 2),-1)')
            ]

        },
        last7Days: {
            title: "Last 7 days",
            operator: "datebetween",
            value:
                [
                    getDateExpression('AddSeconds(AddDays(Today(), -7), -1)'),
                    getDateExpression('LocalDateTimeNow()')
                ]
        },
        last14Days: {
            title: "Last 14 days",
            operator: "datebetween",
            value:
                [
                    getDateExpression('AddSeconds(AddDays(Today(), -14), -1)'),
                    getDateExpression('LocalDateTimeNow()')
                ]
        },
        last30Days: {
            title: "Last 30 days",
            operator: "datebetween",
            value:
                [
                    getDateExpression('AddSeconds(AddDays(Today(), -30), -1)'),
                    getDateExpression('LocalDateTimeNow()')
                ]
        }
    }



    const dateExpressionValues =
    {
        'LocalDateTimeNow()': vDate,
        'LocalDateTimeYesterday()': new Date(vDate.getTime() - 24 * 60 * 60 * 1000),
        'LocalDateTimeDayAfterTomorrow()': new Date(vDate.getTime() + 24 * 2 * 60 * 60 * 1000),
        'LocalDateTimeLastWeek()': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - vDate.getDay() - 6),
        'LocalDateTimeNextMonth()': new Date(vDate.getFullYear(), vDate.getMonth() + 1, 1),
        'LocalDateTimeNextWeek()': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - vDate.getDay() + 8),
        'LocalDateTimeNextYear()': new Date(vDate.getFullYear() + 1, 0, 1),
        'LocalDateTimeThisMonth()': new Date(vDate.getFullYear(), vDate.getMonth(), 1),
        'LocalDateTimeThisWeek()': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - vDate.getDay() + 1),
        'LocalDateTimeThisYear()': new Date(vDate.getFullYear(), 0, 1),
        'LocalDateTimeTomorrow()': new Date(vDate.getTime() + 24 * 60 * 60 * 1000),
        'LocalDateTimeTwoWeeksAway()': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - vDate.getDay() + 14),

        'AddMonths(LocalDateTimeThisMonth(), 2)': new Date(vDate.getFullYear(), vDate.getMonth() + 2, 0),
        'AddYears(LocalDateTimeThisYear(), 2)': new Date(vDate.getFullYear() + 1, 12, 0),

        'AddYears(LocalDateTimeThisYear(), -1)': new Date(vDate.getFullYear() - 1, 0, 1),
        'AddMonths(LocalDateTimeThisMonth(), -1)': new Date(vDate.getFullYear(), vDate.getMonth() - 1, 1),

        'AddDays(AddMonths(LocalDateTimeNextMonth(), 1), -1)': new Date(vDate.getFullYear(), vDate.getMonth() + 2, 0), // next month end
        'AddSeconds(AddMonths(LocalDateTimeNextMonth(),1), -1)': new Date(vDate.getFullYear(), vDate.getMonth() + 2, 0, 23, 59, 59), // next month end

        'AddDays(LocalDateTimeLastWeek(), 6)': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - vDate.getDay(), 23, 59, 59), // next week end
        'AddDays(LocalDateTimeLastWeek(),7)': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - vDate.getDay(), 23, 59, 59), // next week end

        'AddDays(LocalDateTimeThisWeek(), 6)': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - vDate.getDay() + 7, 23, 59, 59), // this week end
        "AddSeconds(AddDays(LocalDateTimeThisWeek(),7),-1)": new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - vDate.getDay() + 7, 23, 59, 59),  // this week end

        'AddDays(LocalDateTimeNextWeek(), 6)': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - vDate.getDay() + 14, 23, 59, 59), // next week end
        'AddSeconds(AddDays(UserDateTimeNextWeek(),7),-1)': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - vDate.getDay() + 14, 23, 59, 59), // next week end

        'AddDays(LocalDateTimeThisMonth(), -1)': new Date(vDate.getFullYear(), vDate.getMonth(), 0), // this month end
        'AddSeconds(LocalDateTimeThisMonth(), -1)': new Date(vDate.getFullYear(), vDate.getMonth(), 0), // this month end

        'AddDays(LocalDateTimeNextMonth(), -1)': new Date(vDate.getFullYear(), vDate.getMonth() + 1, 0, 23, 59, 59), // next month end, correct c#
        'AddSeconds(LocalDateTimeNextMonth(),-1)': new Date(vDate.getFullYear(), vDate.getMonth() + 1, 0, 23, 59, 59), // next month end, correct c#

        'AddDays(LocalDateTimeThisYear(), -1)': new Date(vDate.getFullYear(), 0, 0, 23, 59, 59), // last year end
        'AddSeconds(LocalDateTimeThisYear(),-1)': new Date(vDate.getFullYear(), 0, 0, 23, 59, 59), // last year end

        'AddDays(LocalDateTimeNextYear(), -1)': new Date(vDate.getFullYear(), 12, 0, 23, 59, 59), // this year end
        'AddSeconds(LocalDateTimeNextYear(),-1)': new Date(vDate.getFullYear(), 12, 0, 23, 59, 59), // this year end

        'AddDays(AddYears(LocalDateTimeThisYear(), 2), -1)': new Date(vDate.getFullYear() + 1, 12, 0, 23, 59, 59), // next year end,
        'AddSeconds(AddYears(LocalDateTimeThisYear(), 2), -1)': new Date(vDate.getFullYear() + 1, 12, 0, 23, 59, 59), // next year end,

        'AddDays(Today(), -6)': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - 8, 23, 59, 59), // last 7 days
        'AddSeconds(AddDays(Today(), -7), -1)': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - 8, 23, 59, 59), // last 7 days

        'AddDays(Today(), -13)': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - 15, 23, 59, 59), // last 14 days
        'AddSeconds(AddDays(Today(), -14, -1)': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - 15, 23, 59, 59), // last 14 days

        'AddDays(Today(), -29)': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - 30, 23, 59, 59), // last 30 days
        'AddSeconds(AddDays(Today(), -30, -1)': new Date(vDate.getFullYear(), vDate.getMonth(), vDate.getDate() - 30, 23, 59, 59), // last 30 days
    };

    function getDateExpression(exp: string) {
        if (exp && exp.constructor === String) {
            exp = exp.trim();
            exp = exp.replace(/'/g, "");
            for (var vExp in dateExpressions) {
                if (vExp.toLowerCase() === exp.toLowerCase()) {
                    return dateExpressions[vExp];
                } else if ((dateExpressions[vExp] && dateExpressions[vExp] === exp)) {
                    //return vExp;
                    return dateExpressions[vExp];
                }
            }
        }
        return null;

    }

    function getDateExpressionValue(exp: string) {
        return dateExpressionValues[getDateExpression(exp)];
    }

    function isDateExpression(exp: string) {
        if (exp && exp.constructor === String) {
            exp = exp.trim();
            exp = exp.replace(/'/g, "");
            for (var vExp in dateExpressions) {
                if (vExp.toLowerCase() === exp.toLowerCase() || (dateExpressions[vExp] && dateExpressions[vExp] === exp)) {
                    return true;
                }
            }

        }
        return false;

    }

    self.o365.exportScripts({
        dateExpressions,
        dateExpressionValues,
        getDateExpression,
        getDateExpressionValue,
        isDateExpression,
        combinedExpressions,
        tryGeCombinedtDateExpression,
        getCombinedExpressionByID,
        getCombinedExpressionIndex,
        getCombinedExpressionIdByIndex
    });
})();
